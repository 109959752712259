.canvas
    display: flex
    flex-wrap: wrap
    justify-content: center
    padding: 2em
    width: 100%
    margin: 0 auto

    h1
        width: 100%
        font-size: 2.5em
        font-weight: 600
        margin-bottom: 1em
