html
    box-sizing: border-box
    background: #F4F4F4

*,
*:before,
*:after
    box-sizing: inherit

::selection
    background: #0097e6
    color: #FFF

::-moz-selection
    background: #0097e6
    color: #FFF

body
    font-family: -apple-system, "Helvetica Neue", "Lucida Grande"
    font-size: 10px
    background: #F4F4F4
