
#setup-game
    display: block
    width: 100%
    max-width: 500px

    h2
        font-size: 1.35em
        font-weight: 500
        margin-bottom: 1em
        width: 100%

    fieldset
        width: 100%
        margin-top: 2em

        &:first-child
            margin-top: 0

#game
    // padding: 2em
    // background: #487eb0

    #player-names
        margin-bottom: 7.5em

    thead
        th
            font-size: 1.3em
            padding: 1em
            line-height: 1.35

    tbody
        input
            display: table
            margin: 0
            width: 100%
            text-align: center

            &.error
                border-color: red

    #live-scores
        position: fixed
        bottom: 0
        left: 0
        width: 100%
        background: #F5F5F5

        ul
            display: flex
            padding: 2em
            justify-content: center
            width: 100%

        input
            display: block
            margin: 0
            width: 100%
            text-align: center
            color: #FFF
            background: #7f8fa6

            &:hover,
            &:active,
            &:focus
                outline: 0

            &.win
                background: green

    #save-game
        display: flex
        margin: 2em auto 0
