input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
    -webkit-appearance: none
    margin: 0

input[type="text"],
input[type="email"],
input[type="tel"],
select,
textarea
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none

button[type="button"]
    color: #FFF
    background-color: #0F6BEA
    border: 1px solid #0056E7
    border-radius: 5px
    padding: 1em 1.5em
    cursor: pointer
    transition: background .3s ease-in-out
    background-size: 16px
    background-repeat: no-repeat
    background-position: center
    text-indent: -9999em

    @include bp($m)
        font-size: 16px

    &:hover
        background-color: #0056E7

    &#step-dec
        background-image: url('/assets/images/icon-minus.svg')

    &#step-inc
        background-image: url('/assets/images/icon-add.svg')

input[type="number"]
    width: 20%
    -moz-appearance: textfield
    border: 2px solid #F5F5F5
    border-radius: 5px
    padding: 1em
    text-align: center
    margin: 0 1em

    @include bp($m)
        font-size: 16px

    &:hover,
    &:focus,
    &:active
        // border: #0F6BEA
        outline: 2px solid #0F6BEA
        border: 2px solid #FFF

input[type="text"],
input[type="tel"]
    width: 100%
    border: 2px solid #F5F5F5
    border-radius: 5px
    padding: 1em
    margin-top: 0.5em

    @include bp($m)
        font-size: 16px

    &:first-child
        margin-top: 0

    &:hover,
    &:focus,
    &:active
        // border: #0F6BEA
        outline: 2px solid #0F6BEA
        border: 2px solid #FFF

select
    width: 100%
    border: 2px solid #F5F5F5
    background: #FFF
    border-radius: 5px
    padding: 1em
    margin-top: 0.5em
    background-image: url('/assets/images/icon-dropdown.svg')
    background-repeat: no-repeat
    background-position: 95% center
    background-size: 16px

    @include bp($m)
        font-size: 16px

    &:hover,
    &:focus,
    &:active
        outline: 2px solid #0F6BEA
        border: 2px solid #FFF

button[type="submit"]
    color: #FFF
    font-size: 1.25em
    font-weight: 500
    background: #0F6BEA
    border: 1px solid #0056E7
    border-radius: 5px
    padding: 1em 1.5em
    cursor: pointer
    transition: background .3s ease-in-out

    &:hover
        background: #0056E7
