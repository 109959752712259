header
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 1.5em
    padding: 1em
    background: #000

    h1
        font-size: 1.6em
        font-weight: 600
        color: #FFF
        margin: 0

    a
        color: #FFF
        text-decoration: none
